import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TableCellSortable } from 'modules/ui';
import { AccountType, getAccountType } from 'modules/accounts';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import styles from './OffersTableHeader.module.scss';
import { Offers } from '../services';

export default function OffersTableHeader({ sort, onSortClick }) {
  const accountType = useSelector(getAccountType);

  return (
    <TableRow>
      {AccountType.isAgent(accountType) && (
        <TableCellSortable
          sort={sort}
          name={Offers.SORT_OPTIONS.CLIENT}
          onClick={onSortClick}
          align="left"
          className={classNames(styles.header, styles.clientCell)}
        >
          Client
        </TableCellSortable>
      )}
      <TableCellSortable
        sort={sort}
        name={Offers.SORT_OPTIONS.PROJECT_NAME}
        onClick={onSortClick}
        className={classNames(styles.header, styles.largeCell)}
      >
        Project
      </TableCellSortable>
      <TableCell
        align="left"
        className={classNames(styles.header, styles.mediumCell)}
      >
        Role
      </TableCell>
      {AccountType.isTalent(accountType) && (
        <TableCell
          align="left"
          className={classNames(styles.header, styles.mediumCell)}
        >
          Content Creator
        </TableCell>
      )}
      <TableCellSortable
        sort={sort}
        name={Offers.SORT_OPTIONS.DATE_RECEIVED}
        onClick={onSortClick}
        className={classNames(styles.header, styles.largeCell)}
      >
        Offer Received
      </TableCellSortable>
      <TableCellSortable
        sort={sort}
        name={Offers.SORT_OPTIONS.DUE_DATE}
        onClick={onSortClick}
        className={classNames(styles.header, styles.largeCell)}
      >
        Expiration Date
      </TableCellSortable>
      <TableCell className={classNames(styles.header, styles.offerStatusCell)}>
        Offer Status
      </TableCell>
    </TableRow>
  );
}

OffersTableHeader.propTypes = {
  sort: PropTypes.object.isRequired,
  onSortClick: PropTypes.func.isRequired,
};
