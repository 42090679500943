import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import {  TableWithPagination } from 'modules/ui';
import { HEADER_SCROLL_OFFSET } from 'modules/ui/components/Page';
import OffersTableRow from './OffersTableRow';
import OffersTableHeader from './OffersTableHeader';
import OffersEmptyPlaceholder from './OffersEmptyPlaceholder';
import styles from './OffersTable.module.scss';

function OffersTable({
  offers,
  sort,
  onSortClick,
  onPageChange,
  onRowsPerPageChange,
  defaultPage,
  defaultRowsPerPage,
  accountType,
  openInviteClient,
  hasNoOffers,
  searchedTalent,
}) {
  const history = useHistory();

  if (isEmpty(offers)) {
    return (
      <OffersEmptyPlaceholder
        accountType={accountType}
        openInviteClient={openInviteClient}
        hasNoOffers={hasNoOffers}
        searchedTalent={searchedTalent}
      />
    );
  }

  function handleRowClick(offerId) {
    history.push({
      pathname: `/offers/${offerId}`,
    });
  }

  function handleRenderHeader() {
    return <OffersTableHeader sort={sort} onSortClick={onSortClick} />;
  }

  function handleRenderRow(offer) {
    return (
      <OffersTableRow
        key={offer.id}
        offer={offer}
        onClick={() => handleRowClick(offer.id)}
      />
    );
  }

  return (
    <TableWithPagination
      count={offers.length}
      rows={offers}
      renderHeader={handleRenderHeader}
      renderRow={handleRenderRow}
      rowsPerPageLabel="Offers Per Page"
      className={styles.table}
      pageChangeScrollY={HEADER_SCROLL_OFFSET}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      defaultPage={defaultPage}
      defaultRowsPerPage={defaultRowsPerPage}
    />
  );
}

OffersTable.propTypes = {
  offers: PropTypes.array.isRequired,
  sort: PropTypes.object.isRequired,
  onSortClick: PropTypes.func.isRequired,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  defaultPage: PropTypes.number,
  defaultRowsPerPage: PropTypes.number,
  accountType: PropTypes.string.isRequired,
  openInviteClient: PropTypes.func.isRequired,
  hasNoOffers: PropTypes.bool,
  searchedTalent: PropTypes.object,
};

export default memo(OffersTable);
