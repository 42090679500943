import React, { memo, useState, useRef } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { AsyncPlayButton, getCurrentAudioUrl } from 'modules/audio';
import { Slider } from 'modules/ui';
import {
  getReadyToPlay,
  getReadyToPlayBlob,
  getAudioSampleKey,
} from '../redux';
import styles from './AudioPreview.module.scss';
import { isBlob } from '../services';

function renderDetails(detail, separator) {
  if (!detail) {
    return null;
  }

  const detailClasses = classNames(
    styles.detail,
    styles[`detail--${separator}`],
  );
  return <span className={detailClasses}>{detail}</span>;
}

function renderTruncatedDetails(details, separator, length) {
  const detailsString = filter(details, detail => detail).join(
    `${separator || ','} `,
  );

  return detailsString;
}

function getTitle(details, file) {
  const name = get(file, 'name');
  const detailsName = get(details, 'name');
  const detailsTitle = get(details, 'title');

  if (detailsName) {
    return details.name;
  }

  if (detailsTitle) {
    return details.title;
  }
  return name;
}

function getDetails(details, detailsPaths) {
  const metaDetails = get(details, 'meta');

  return { ...pick(details, detailsPaths), ...pick(metaDetails, detailsPaths) };
}

function AudioPreview({
  file,
  simple,
  details,
  separator,
  detailsPaths,
  containerStyle,
  titleStyle,
  titleClassName,
  detailsStyle,
  detailsClassName,
  sliderStyle,
  truncateLength,
  description,
  token,
  showTitleWhilePlaying,
  informationClassName,
  durationLabelClassName,
}) {
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [totalSeconds, setTotalSeconds] = useState(0);

  const isReadyToPlay = useSelector(getReadyToPlay);
  const isReadyToPlayBlob = useSelector(getReadyToPlayBlob);
  const currentAudioUrl = useSelector(getCurrentAudioUrl);
  const audioSampleKey = useSelector(getAudioSampleKey);

  // Generating a unique key for every audio file so when there are multiple
  // components with the same file path only the clicked component will play
  const generatedAudioSampleKey = useRef(
    Math.floor(Math.random() * 10000).toString(),
  );

  if (!details) {
    return null;
  }

  function handleSeekClick(e) {
    e.stopPropagation();
  }

  const name = getTitle(details, file);
  const fileDetails = getDetails(details, detailsPaths);

  const titleClasses = classNames(
    styles.title,
    titleClassName,
    simple && styles['title--simple'],
  );
  const detailsClasses = classNames(
    styles.details,
    simple && styles['details--simple'],
    detailsClassName,
  );

  function generateFileUrl() {
    if (details.url && !details.isReplacement) {
      return details.url;
    }
    return URL.createObjectURL(file);
  }

  const isPlaying =
    (isReadyToPlay &&
      currentAudioUrl === generateFileUrl() &&
      generatedAudioSampleKey.current === audioSampleKey) ||
    (isReadyToPlayBlob && isBlob(generateFileUrl()));

  const Title = (
    <div className={titleClasses} style={titleStyle}>
      {name}
    </div>
  );

  return (
    <div className={styles.container} style={containerStyle}>
      <div
        className={classNames(
          styles.playContainer,
          isPlaying && styles.isPlayingContainer,
          isPlaying && !showTitleWhilePlaying && styles.playerBackground,
        )}
        onClick={handleSeekClick}
      >
        <AsyncPlayButton
          fileUrl={generateFileUrl()}
          token={token}
          secondsElapsed={secondsElapsed}
          setSecondsElapsed={setSecondsElapsed}
          setTotalSeconds={setTotalSeconds}
          audioSampleKey={generatedAudioSampleKey.current}
        />
        <div className={styles.titlePlayerContainer}>
          {showTitleWhilePlaying && isPlaying && (
            <div
              className={classNames(styles.information, informationClassName)}
            >
              {Title}
            </div>
          )}
          {!isPlaying && (
            <div
              className={classNames(styles.information, informationClassName)}
            >
              {Title}
              <span className={detailsClasses} style={detailsStyle}>
                {truncateLength
                  ? renderTruncatedDetails(
                      fileDetails,
                      separator,
                      truncateLength,
                    )
                  : map(fileDetails, detail =>
                      renderDetails(detail, separator),
                    )}
              </span>
              {description && (
                <div className={detailsClasses} style={detailsStyle}>
                  {description}
                </div>
              )}
            </div>
          )}
          {isPlaying && (
            <Slider
              min={0}
              max={totalSeconds}
              value={secondsElapsed}
              setValue={setSecondsElapsed}
              containerClassName={
                showTitleWhilePlaying && styles.sliderContainer
              }
              durationLabelClassName={durationLabelClassName}
              sliderStyle={sliderStyle}
            />
          )}
        </div>
      </div>
    </div>
  );
}

AudioPreview.propTypes = {
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  containerStyle: PropTypes.object,
  titleStyle: PropTypes.string,
  titleClassName: PropTypes.string,
  detailsStyle: PropTypes.string,
  sliderStyle: PropTypes.string,
  truncateLength: PropTypes.number,
  separator: PropTypes.string,
  detailsPaths: PropTypes.array,
  simple: PropTypes.bool,
  details: PropTypes.object,
  detailsClassName: PropTypes.string,
  description: PropTypes.string,
  token: PropTypes.string.isRequired,
  showTitleWhilePlaying: PropTypes.bool,
  informationClassName: PropTypes.string,
  durationLabelClassName: PropTypes.string,
};

export default memo(AudioPreview);
