import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Colors } from 'modules/theme';
import { Icon } from 'modules/ui';
import classes from './Notification.module.scss';

const StyledSnackbarContent = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    minWidth: '463px',
    maxWidth: '70%',
    minHeight: '60px',
    cursor: 'pointer',
    borderRadius: '8px',
    // TODO fix this for IE 11 flex bug https://stackoverflow.com/a/51218681/9801221 be careful of this messing up in other browser when the message is longer
  },
})(SnackbarContent);

const styles = {
  success: {
    backgroundColor: Colors.darkGrey,
    color: 'white',
  },
  warning: {
    backgroundColor: Colors.warning,
    color: 'black',
  },
  error: {
    backgroundColor: Colors.error,
    color: 'white',
  },
};

function Notification({ notification, onClose, anchorOrigin, duration }) {
  if (notification === null) {
    return;
  }

  const { message, type } = notification;

  const ActionIcon = (
    <Icon
      name={type}
      color={styles[type].color}
      size={26}
      className={classes.icon}
    />
  );

  return (
    <Snackbar
      open={!!message}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      autoHideDuration={duration}
      key={message}
      onClick={onClose}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    >
      <StyledSnackbarContent
        style={styles[type]}
        message={
          <div className={classes.message}>
            {ActionIcon}
            {message}
          </div>
        }
      />
    </Snackbar>
  );
}

Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['success', 'warning', 'error']).isRequired,
  anchorOrigin: PropTypes.object,
  duration: PropTypes.number,
};

Notification.defaultProps = {
  duration: 5000,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
};

export default memo(Notification);
