import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ConfirmationDialog, IconButton } from 'modules/ui';
import styles from './FileControls.module.scss';

export default function FileControls({
  onRemoveFile,
  onReplaceFile,
  deleteInProgress,
  setDeleteInProgress,
  removeIconClassName,
}) {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleRemoveFileClick = () => setShowConfirmation(true);
  const handleDialogAnswer = confirmedDelete => {
    setShowConfirmation(false);

    if (!confirmedDelete) {
      return;
    }

    if (setDeleteInProgress) {
      setDeleteInProgress(true);
    }

    if (onRemoveFile) {
      onRemoveFile().finally(() => {
        if (setDeleteInProgress) {
          setDeleteInProgress(false);
        }
      });
    }
  };

  return (
    <div className={styles.container}>
      <IconButton
        small
        secondary
        loading={deleteInProgress}
        iconName="trash"
        iconColor="black"
        onClick={handleRemoveFileClick}
        className={removeIconClassName || styles.trashButton}
      />
      {onReplaceFile && (
        <Button
          secondary
          type="button"
          className={styles.replaceButton}
          onClick={onReplaceFile}
        >
          Replace
        </Button>
      )}
      {showConfirmation && (
        <ConfirmationDialog
          withOverlay
          title="Are you sure you want to remove this file?"
          actionBtnLabel="Remove"
          onCancel={() => handleDialogAnswer(false)}
          onAction={() => handleDialogAnswer(true)}
        />
      )}
    </div>
  );
}

FileControls.propTypes = {
  onRemoveFile: PropTypes.func,
  onReplaceFile: PropTypes.func,
  deleteInProgress: PropTypes.bool,
  setDeleteInProgress: PropTypes.func,
  removeIconClassName: PropTypes.string,
};
