import { Api } from 'modules/core';

export function generateAssetURL(assetType, assetClass, context) {
  const endpoint = 'assets/actions/generate-url';
  const item = {
    resourceType: 'asset-actions',
    assetType,
    assetClass,
    context,
  };

  return Api.create(endpoint, item);
}

export function signUploadURL(fileName, assetPath) {
  const endpoint = 'assets/actions/sign-upload';
  const item = {
    resourceType: 'asset-actions',
    fileName,
    assetPath,
  };

  return Api.create(endpoint, item);
}

export function uploadFileToServer(assetUploadPath, file) {
  return Api.upload(assetUploadPath, file);
}

export function signDownloadURL(assetPath, headers) {
  if (!assetPath) {
    return Promise.reject(new Error('No image to get.'));
  }

  const endpoint = 'assets/actions/sign-download';
  const item = {
    resourceType: 'asset-actions',
    assetPath,
  };

  return Api.create(endpoint, item, {}, {}, headers);
}

export function downloadAsset(currentAudioUrl, convertToUrl = true) {
  return fetch(currentAudioUrl)
    .then(response => response.blob())
    .then(blob => {
      if (!convertToUrl) {
        return blob;
      }

      let url = '';
      const myURL = window.URL || window.webkitURL;

      try {
        url = myURL.createObjectURL(blob);
      } catch (err) {
        // Firefox
        url = myURL.createObjectURL(blob);
      }

      return url;
    });
}

export function signProducerApplicationUploadUrl(fileName, assetPath) {
  const endpoint = 'assets/actions/sign-producer-approval-clip-upload';
  const item = {
    resourceType: 'asset-actions',
    fileName,
    assetPath,
  };

  return Api.create(endpoint, item);
}
