import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import { AccountType, getAccount, getAccountType } from 'modules/accounts';
import { loadTalent, getTalentById } from 'modules/talents';
import { loadAudition, getAuditionById } from 'modules/auditions';
import {
  setAuditionApplicationUnreadStatus,
  getApplicationByTalentAndAuditionId,
} from 'modules/audition-applications';
import {
  createAuditionMessage,
  getExchangedMessages,
} from 'modules/audition-messages';
import { createAudioClip } from 'modules/audio-clips';
import { ChatWindow } from 'modules/chat';

import { loadAuditionApplicationMessaging } from '../redux';

function AuditionApplicationChatWindow({
  auditionId,
  talentId,
  options,
  ...otherProps
}) {
  const dispatch = useDispatch();

  const audition = useSelector(getAuditionById(auditionId));
  const auditionName = get(audition, 'name');

  const talent = useSelector(getTalentById(talentId));

  const producer = get(audition, 'producer');
  const producerId = get(producer, 'id');

  const account = useSelector(getAccount);
  const accountId = get(account, 'id');
  const accountType = useSelector(getAccountType);

  const chattingTo = AccountType.isTalent(accountType) ? producer : talent;
  const chattingWith = get(chattingTo, 'fullName');
  const profileImage = get(chattingTo, 'profileImage');

  const application = useSelector(
    getApplicationByTalentAndAuditionId(talentId, auditionId),
  );
  const applicationId = get(application, 'id');

  const messages = useSelector(
    getExchangedMessages(talentId, producerId, auditionId),
  );

  const [loadingMessages, setLoadingMessages] = useState(true);

  useEffect(() => {
    Promise.all([
      dispatch(loadTalent(talentId)),
      dispatch(loadAudition(auditionId)),
      dispatch(
        loadAuditionApplicationMessaging(accountId, auditionId, talentId),
      ),
    ]).then(() => setLoadingMessages(false));
  }, [dispatch, talentId, auditionId, accountId]);

  async function handleSubmitClick(data) {
    const file = get(data, 'file');
    const message = get(data, 'text');

    if (message === undefined) {
      return Promise.resolve();
    }

    const messagePayload = await dispatch(
      createAuditionMessage(
        auditionId,
        applicationId,
        get(producer, 'accountId'),
        message,
      ),
    );

    await dispatch(
      setAuditionApplicationUnreadStatus(auditionId, applicationId),
    );

    const messageId = get(messagePayload, 'data.id');

    if (!messageId) {
      return Promise.resolve();
    }

    if (file) {
      return dispatch(
        createAudioClip(audition.id, application.id, messageId, data),
      ).then(result => {
        return result;
      });
    }

    return Promise.resolve();
  }

  return (
    <ChatWindow
      headingTitle={auditionName}
      headingSubtitle={chattingWith}
      photoUrl={profileImage}
      messages={messages}
      onSubmitClick={handleSubmitClick}
      isLoadingMessages={loadingMessages}
      options={options}
      enableSampleUploading
      {...otherProps}
    />
  );
}

AuditionApplicationChatWindow.propTypes = {
  auditionId: PropTypes.string.isRequired,
  talentId: PropTypes.string.isRequired,
  className: PropTypes.string,
  options: PropTypes.node,
};

export default AuditionApplicationChatWindow;
