import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { Notification } from 'modules/ui';
import { clearNotification, getNotification } from '../redux';

export default function NotificationProvider() {
  const dispatch = useDispatch();
  const notification = useSelector(getNotification);
  const duration = get(notification, 'duration');
  
  function handleOnClose() {
    dispatch(clearNotification());
  }

  return (
    notification && (
      <Notification
        notification={notification}
        duration={duration}
        onClose={handleOnClose}
      />
    )
  );
}
