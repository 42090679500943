import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FileUpload, Validator } from 'modules/assets';
import { ConfirmationDialog, Input } from 'modules/ui';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { MessageAudioPreview } from './MessageAudioPreview';
import { FormAudioPreview } from './FormAudioPreview';
import { resetAudioUrls } from '../redux';
import styles from './AudioInput.module.scss';

export default function AudioInput({
  className,
  value,
  name,
  file,
  detailsPaths,
  buttonLabel,
  setFormValue,
  description,
  onRemoveFileClick,
  onSelectFile,
  uploadBtnStyle,
  uploadBtnClassName,
  noBorder,
  noReplaceButton,
  uploadButtonLabel,
  moreSamplesBtn,
  moreSamplesBtnDisabled,
  simpleButtonInput,
  setRef,
  disabled,
  fileContainerClassName,
  descriptionClassName,
  ...otherProps
}) {
  const inputRef = useRef(setRef);
  const [fileError, setFileError] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const dispatch = useDispatch();

  function handleReplaceClick() {
    dispatch(resetAudioUrls());
    inputRef.current.click();
  }

  function handleSelectFile(newFile) {
    const newFileError = Validator.validate(newFile, 'audio');

    if (!isEmpty(newFileError)) {
      setFileError(newFileError);
      return;
    }

    setFileError(false);

    if (setFormValue) {
      setFormValue(name, newFile);
    }

    if (onSelectFile) {
      onSelectFile(newFile);
    }
  }

  const handleDialogAnswer = wantsUpload => {
    if (!wantsUpload) {
      inputRef.current.value = null;
      setFileError(false);
      return;
    }

    inputRef.current.click();
  };

  function handleRemoveFileClick() {
    if (setFormValue) {
      setFormValue(name, null);
    }

    if (onRemoveFileClick) {
      return onRemoveFileClick();
    }

    return Promise.resolve();
  }

  const inputContainerClassnames = classNames(className, styles.inputContainer);

  const containerClasses = classNames(
    styles.container,
    fileError && styles['container--error'],
  );

  return (
    <>
      <Input containerClassName={inputContainerClassnames} {...otherProps}>
        <div className={containerClasses}>
          <FileUpload
            inputName={name}
            disabled={disabled}
            noBorder={noBorder}
            file={file}
            error={fileError}
            details={value}
            setRef={inputRef}
            description={description}
            buttonLabel={buttonLabel}
            onSelectFile={handleSelectFile}
            uploadBtnStyle={uploadBtnStyle}
            uploadBtnClassName={uploadBtnClassName}
            moreSamplesBtn={moreSamplesBtn}
            moreSamplesBtnDisabled={moreSamplesBtnDisabled}
            deleteInProgress={deleteInProgress}
            descriptionClassName={descriptionClassName}
          />
          {value &&
            (simpleButtonInput ? (
              <MessageAudioPreview
                value={value}
                file={file}
                disabled={disabled}
                detailsPaths={detailsPaths}
                noReplaceButton={noReplaceButton}
                handleRemoveFileClick={handleRemoveFileClick}
                handleReplaceClick={handleReplaceClick}
                deleteInProgress={deleteInProgress}
                setDeleteInProgress={setDeleteInProgress}
                className={fileContainerClassName}
              />
            ) : (
              <FormAudioPreview
                deleteInProgress={deleteInProgress}
                setDeleteInProgress={setDeleteInProgress}
                value={value}
                file={file}
                detailsPaths={detailsPaths}
                handleRemoveFileClick={handleRemoveFileClick}
                handleReplaceClick={handleReplaceClick}
              />
            ))}
        </div>
      </Input>
      {fileError && (
        <ConfirmationDialog
          withOverlay
          title={fileError.title}
          message={fileError.message}
          subtitleText={fileError.subtitle}
          subtitleClassName={styles.dialogSubtitle}
          actionBtnLabel="Retry upload"
          onCancel={() => handleDialogAnswer(false)}
          onAction={() => handleDialogAnswer(true)}
        />
      )}
    </>
  );
}

AudioInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.object,
  detailsPaths: PropTypes.array,
  setFormValue: PropTypes.func,
  description: PropTypes.string,
  onRemoveFileClick: PropTypes.func,
  onSelectFile: PropTypes.func,
  moreSamplesBtn: PropTypes.bool,
  moreSamplesBtnDisabled: PropTypes.bool,
  buttonLabel: PropTypes.string,
  uploadButtonLabel: PropTypes.string,
  uploadBtnStyle: PropTypes.object,
  uploadBtnClassName: PropTypes.string,
  noBorder: PropTypes.bool,
  noReplaceButton: PropTypes.bool,
  simpleButtonInput: PropTypes.bool,
  setRef: PropTypes.object,
  file: PropTypes.object,
  disabled: PropTypes.bool,
  fileContainerClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
};

AudioInput.defaultProps = {
  simpleButtonInput: false,
};
