import { Api } from 'modules/core';
import get from 'lodash/get';
import {
  createApplication,
  setAuditionApplicationUnreadStatus,
} from '../audition-applications';
import { createAuditionMessage } from '../audition-messages';
import { createAudioClip } from '../audio-clips';

const INCLUDE = 'producer,projectRole.project,nonDisclosureAgreement';

export function fetchSavedAuditions(talentId) {
  if (!talentId) {
    return Promise.resolve(null);
  }

  const endpoint = `talents/${talentId}/saved-auditions`;
  const params = { include: INCLUDE };

  return Api.find(endpoint, params);
}

export function acceptNdaAction(auditionId) {
  const endpoint = `auditions/${auditionId}/nda/actions/accept`;
  const data = { resourceType: 'nda-acceptances' };

  return Api.create(endpoint, data);
}

export function fetchNdaAcceptance(auditionId) {
  const endpoint = `auditions/${auditionId}/nda/acceptances/me`;
  return Api.find(endpoint);
}

export function saveAuditionAction(talentId, auditionId) {
  const endpoint = `talents/${talentId}/saved-auditions/${auditionId}`;

  if (!talentId || !auditionId) {
    return Promise.resolve(null);
  }

  return Api.create(endpoint);
}

export function unsaveAuditionAction(talentId, auditionId) {
  const endpoint = `talents/${talentId}/saved-auditions/${auditionId}`;

  if (!talentId || !auditionId) {
    return Promise.resolve(null);
  }

  return Api.deleteResource(endpoint);
}

export function applyToAudition(data, talentId, auditionId) {
  return async dispatch => {
    const applicationPayload = await dispatch(
      createApplication(talentId, auditionId),
    );
    const applicationId = get(applicationPayload, 'data.id');

    if (!applicationId) {
      return Promise.resolve();
    }

    await dispatch(
      setAuditionApplicationUnreadStatus(auditionId, applicationId),
    );

    const file = get(data, 'file');
    const text = get(data, 'message') || '';

    if (file || text) {
      const messagePayload = await dispatch(
        createAuditionMessage(auditionId, applicationId, talentId, text),
      );

      const messageId = get(messagePayload, 'data.id');

      if (!messageId) {
        return Promise.resolve();
      }

      return dispatch(
        createAudioClip(auditionId, applicationId, messageId, data),
      );
    }
  };
}
