import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import get from 'lodash/get';

import { getAccount } from 'modules/accounts';
import { MessagingCard } from 'modules/chat';

function AuditionMessagingCard({
  application,
  subtitle,
  profileImage,
  onClick,
}) {
  const account = useSelector(getAccount);
  const accountId = get(account, 'id');

  const updatedAt = get(application, 'updatedAt');
  const unreadMessageAccountId = get(application, 'unreadMessageAccountId');

  const audition = get(application, 'audition');
  const auditionName = get(audition, 'name');

  const lastMessage = get(application, 'lastMessage');
  const lastMessageText = get(lastMessage, 'text');

  return (
    <MessagingCard
      title={auditionName}
      subtitle={subtitle}
      photoUrl={profileImage}
      updatedAt={updatedAt}
      isUnread={unreadMessageAccountId === accountId}
      message={lastMessageText}
      onClick={onClick}
    />
  );
}

AuditionMessagingCard.propTypes = {
  application: PropTypes.object.isRequired,
  subtitle: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  profileImage: PropTypes.string,
};

export default AuditionMessagingCard;
