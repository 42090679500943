import React from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import Dictionary, { DictionaryUtil } from 'modules/dictionary';
import styles from './RecordingLocationInlineSection.module.scss';

function RecordingLocationInlineSection({ recordingLocations, studioAddress }) {
  const talentStudioSelected = includes(
    recordingLocations,
    Dictionary.RECORDING_LOCATIONS.TALENT_STUDIO,
  );
  const onSiteStudioSelected = includes(
    recordingLocations,
    Dictionary.RECORDING_LOCATIONS.ON_SITE_STUDIO,
  );

  const talentStudioString =
    talentStudioSelected &&
    DictionaryUtil.getDictionaryLabel(
      Dictionary.RECORDING_LOCATIONS.TALENT_STUDIO,
      Dictionary.RECORDING_LOCATION_OPTIONS,
    );

  const onSiteStudioString =
    onSiteStudioSelected &&
    DictionaryUtil.getDictionaryLabel(
      Dictionary.RECORDING_LOCATIONS.ON_SITE_STUDIO,
      Dictionary.RECORDING_LOCATION_OPTIONS,
    );

  const sectionEmpty = !talentStudioSelected && !onSiteStudioSelected;

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Recording location</h3>
      {sectionEmpty && <p className="body14 grayishBlueColor">Any</p>}
      {talentStudioSelected && (
        <span className="body16">{talentStudioString}</span>
      )}
      {onSiteStudioSelected && (
        <>
          <div className="body16 mt-12">{onSiteStudioString}</div>
          <span className="body16">
            {studioAddress || 'Address not provided.'}
          </span>
        </>
      )}
    </div>
  );
}

RecordingLocationInlineSection.propTypes = {
  recordingLocations: PropTypes.array.isRequired,
  studioAddress: PropTypes.string,
};

export default RecordingLocationInlineSection;
